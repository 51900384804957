<template>
  <div class="tab-pane fade" id="second">
    <div class="control-group form-group">
      <label class="form-label text-dark">YouTube Bağlantısı</label>
      <div class="d-flex ad-post-details">
        <input
          type="text"
          class="form-control"
          name="youtube"
          placeholder="Youtube bağlantısını ekleyiniz"
        />
      </div>
      <br />
      <button
        type="button"
        @click="videoAdd"
        id="saveButton"
        class="btn btn-primary w-100"
      >
        Ekle
      </button>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xl-6" v-for="(item, i) in list" :key="i">
        <div class="item-card">
          <iframe
          class="col-lg-12 col-md-12 col-xl-12"
            height="200"
            :src="'https://www.youtube.com/embed/' + (item.path.split('?v=')[1]==null?item.path.split('.be/')[1] : item.path.split('?v=')[1])"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="item-card9-icons">
          <a
            @click="remove(item.id)"
            class="item-card9-icons1 wishlist"
            style="color: white"
          >
            <i class="fa fa fa-trash"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Video Galerisi";
    this.$store.dispatch("mediaVideosGet").then((value) => {
      this.list = value;
    });
  },
  data() {
    return {
      imgDataUrl: "",
      list: [],
    };
  },
  methods: {
    remove(id) {
      this.$store.dispatch("mediaVideosRemove", { id: id }).then((response) => {
        this.$vToastify.success(
          "Video başarılı bir şekilde silindi",
          "Başarılı!"
        );
        this.list = response.list;
        document.getElementById("saveButton").innerText = "Ekle";
        document.getElementById("saveButton").disabled = false;
      });
    },
    videoAdd() {
      document.getElementById("saveButton").innerText = "Video Yükleniyor...";
      document.getElementById("saveButton").disabled = true;
      let path = document.querySelector("input[name=youtube]").value;
      this.$store
        .dispatch("mediaVideosUpload", { path: path })
        .then((response) => {
          document.getElementById("saveButton").innerText = "Ekle";
          document.getElementById("saveButton").disabled = false;
          document.querySelector("input[name=youtube]").value="";
          if (response.type == "error") {
            if (response.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
          } else {
            this.$vToastify.success(
              "Video başarılı bir şekilde yüklendi",
              "Başarılı!"
            );
            this.list = response.list;
          }
        });
    },
  },
  components: {},
  mounted() {},
};
</script>