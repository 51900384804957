<template>
  <div class="tab-pane fade show active" id="first">
    <div class="row">
      <div class="col-md-12">
        <croppa
          v-model="myCroppa"
          :width="l"
          :height="l"
          :placeholder="'Resim Seçin 500x500'"
          :accept="'image/*'"
          :file-size-limit="0"
          :quality="c"
          :zoom-speed="3"
          :disabled="false"
          :disable-drag-and-drop="false"
          :disable-click-to-choose="false"
          :disable-drag-to-move="false"
          :disable-scroll-to-zoom="false"
          :disable-rotation="false"
          :prevent-white-space="false"
          :reverse-scroll-to-zoom="false"
          :show-remove-button="true"
          :remove-button-color="'red'"
          :remove-button-size="0"
          canvas-color="transparent"
        ></croppa>
      </div>
    </div>
    <button
      type="button"
      @click="fileUpload"
      id="saveButton"
      class="btn btn-primary w-100"
    >
      Yükle
    </button>
    <hr />
    <div class="row">
      <div
        class="col-lg-6 col-md-12 col-xl-4"
        v-for="(item, i) in list"
        :key="i"
      >
        <div class="item-card9-imgs">
          <lazy-image
            :src="'https://storage.terapivitrini.com/' + item.image_url"
            alt="img"
            class="cover-image"
          />
        </div>
        <div class="item-card9-icons">
          <a
            @click="remove(item.id)"
            class="item-card9-icons1 wishlist"
            style="color: white"
          >
            <i class="fa fa fa-trash"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Resim Galerisi";
    this.$store.dispatch("mediaGalleryGet").then((value) => {
      this.list = value;
    });
    this.onResize();
  },

  data() {
    return {
      imgDataUrl: "",
      list: [],
      l: 500,
      c: 1,
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    remove(id) {
      this.$store
        .dispatch("mediaGalleryRemove", { id: id })
        .then((response) => {
          this.$vToastify.success(
            "Resim başarılı bir şekilde silindi",
            "Başarılı!"
          );
          this.$store.dispatch("mediaGalleryGet").then((value) => {
            this.list = value;
          });
          document.getElementById("saveButton").innerText = "Yükle";
          document.getElementById("saveButton").disabled = false;
        });
    },
    fileUpload() {
      document.getElementById("saveButton").innerText = "Resim Yükleniyor...";
      document.getElementById("saveButton").disabled = true;
      let base64img = this.myCroppa.generateDataUrl();
      let file = this.dataURLtoFile(base64img, "profile_image.jpg");
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("mediaGalleryUpload", formData).then((response) => {
        document.getElementById("saveButton").innerText = "Yükle";
        document.getElementById("saveButton").disabled = false;
        if (response.type == "error") {
          if (response.message == "ERRxUAR") {
            this.$vToastify.warning(
              "Onay sürecindeki profil güncellenemez",
              "Uyarı!"
            );
          }
        } else {
          this.$vToastify.success(
            "Resim başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          this.myCroppa.remove();
          this.$store.dispatch("mediaGalleryGet").then((value) => {
            this.list = value;
          });
        }
      });
    },
    onResize() {
      if (window.innerWidth <= 770) {
        this.l = 250;
        this.c = 2;
      } else {
        this.l = 500;
        this.c = 1;
      }
    },
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>