<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Medya İşlemleri</h3>
        <div style="position: absolute; right: 10px">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin"><i class="fa fa-info-circle"  style="font-size:25px;color: rgb(187, 116, 148);" ></i></button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/medya.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form id="commentForm" method="get" class="form-horizontal mb-0">
          <div id="rootwizard" class="border pt-0">
            <ul class="nav nav-tabs nav-justified">
              <li class="nav-item">
                <a href="#first" data-bs-toggle="tab" class="nav-link font-bold active"
                  >Görsel</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#second"
                  data-bs-toggle="tab"
                  class="nav-link font-bold"
                  >Video</a
                >
              </li>
            </ul>
            <div class="tab-content card-body mt-3 mb-0 b-0">
              <ImagaGallery/>
              <ImageGallery />
              <VideoGallery />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ImagaGallery from './Media/ImagaGallery.vue';
import VideoGallery from './Media/VideoGallery.vue';
export default {
  created() {},
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
  },
  components: { ImagaGallery, VideoGallery },
  mounted() {},
};
</script>